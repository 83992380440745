define('xchatter-webapp/validations/purchase', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fullName: (0, _validators.validatePresence)(true),
    cardNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ is: 16 })],
    cardExpiry: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ is: 5 })],
    cardCVC: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3, max: 4 })],
    zipCode: [(0, _validators.validatePresence)(true)]
  };
});