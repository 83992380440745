define('xchatter-webapp/router', ['exports', 'xchatter-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('terms-and-conditions');
    this.route('privacy-policy');
    this.route('cookie-policy');
    this.route('content-policy');
    this.route('code-verification');
    this.route('sign-up');
    this.route('new-sign-up');
    this.route('stay-updated');
    this.route('waitlist');
    this.route('welcome');
    this.route('about-you', function () {
      this.route('taste');
      this.route('personality');
      this.route('interests');
    });
    this.route('matches', function () {
      this.route('loading');
    });
    this.route('chats', function () {
      this.route('show', { path: '/:username' });
    });
    this.route('ais', function () {
      this.route('show', { path: '/:username' });
    });
    this.route('live-call');
    this.route('feed', function () {});
    this.route('messages');
    this.route('message');
    this.route('search');
    this.route('post');
    this.route('bookmarks');
    this.route('profile', { path: 'profile/:modelname' });
    this.route('add-post');
    this.route('live-call-demo');
    this.route('settings');
    this.route('account');
    this.route('following');
    this.route('privacy');
    this.route('support');
    this.route('about');
    this.route('models');
    this.route('payment', function () {
      this.route('success');
    });
    this.route('payments');
    this.route('paymentprocessing');
    this.route('add-payment');
    this.route('subscriptions');
    this.route('subscription', function () {
      this.route('month');
      this.route('year');
      this.route('pay');
      this.route('success');
    });
    this.route('add-subscription');
    this.route('add-tip');
    this.route('usc-2257');
    this.route('dmca');
    this.route('complaints');
    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});