define('xchatter-webapp/services/main-navbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    routerService: Ember.inject.service('router'),

    isNavbarOpen: false,

    isNavbarShrinked: Ember.computed.not('isNavbarOpen'),
    isSubscriptionsScreen: Ember.computed.equal('routerService.currentRouteName', 'subscriptions'),
    isMessagesScreen: Ember.computed.equal('routerService.currentRouteName', 'messages'),
    isSettingsScreen: Ember.computed.equal('routerService.currentRouteName', 'settings'),
    cannotOpenNavbar: Ember.computed('isSubscriptionsScreen', 'isMessagesScreen', function () {
      return this.get('isSubscriptionsScreen') || this.get('isMessagesScreen') || this.get('isSettingsScreen');
    }),

    openNavbar: function openNavbar() {
      if (this.get('cannotOpenNavbar')) return;
      this.set('isNavbarOpen', true);
    },
    shrinkNavbar: function shrinkNavbar() {
      this.set('isNavbarOpen', false);
    },
    toggle: function toggle() {
      if (this.get('cannotOpenNavbar')) return;
      this.toggleProperty('isNavbarOpen');
    }
  });
});