define('xchatter-webapp/components/message-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isActive:active'],
    isActive: false,
    timer: null,

    touchStart: function touchStart(event) {
      var _this = this;

      event.preventDefault();
      var timer = Ember.run.later(this, function () {
        _this.set('isActive', !_this.get('isActive'));
      }, 300);
      this.set('timer', timer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('isActive', false);
      Ember.run.cancel(this.get('timer'));
    }
  });
});