define('xchatter-webapp/routes/application', ['exports', 'ember-body-class/mixins/body-class'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_bodyClass.default, {
    routeHistoryService: Ember.inject.service('route-history'),
    router: Ember.inject.service(),

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        this.get('routeHistoryService').add(this.get('router.currentRouteName'));
      }
    }
  });
});