define('xchatter-webapp/components/start-loading', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    didEnterViewport: function didEnterViewport() {
      console.log('entered first load component1');
      if (this.get('onDidEnterViewport')) this.get('onDidEnterViewport')();
    },
    didExitViewport: function didExitViewport() {
      console.log('exited component');
    },
    didScroll: function didScroll(direction) {
      console.log(direction); // 'up' || 'down' || 'left' || 'right'
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);
      if (this.get('onWillRender')) this.get('onWillRender')();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setProperties({
        viewportEnabled: true,
        viewportUseRAF: true,
        viewportSpy: true,
        viewportScrollSensitivity: 1,
        viewportRefreshRate: 150,
        viewportTolerance: {
          top: 50,
          bottom: 50,
          left: 20,
          right: 20
        }
      });

      if (this.get('onInsertElement')) this.get('onInsertElement')();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('onWillDestroyElement')) this.get('onWillDestroyElement')();
      this._super.apply(this, arguments);
    }
  });
});