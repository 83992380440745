define('xchatter-webapp/components/messages-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionService: Ember.inject.service('session'),

    scrollToBottomTask: null,

    actions: {
      loadItems: function loadItems() {
        this.get('loadMoreItems')();
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._scrollToBottom();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this._scrollToBottomSmooth();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.scrollToBottomTask);
    },
    onImageError: function onImageError(e) {
      e.target.src = '/img/female-placeholder.png';
    },
    onAvatarError: function onAvatarError(e) {
      e.target.src = '/img/female-avatar-placeholder.png';
    },
    _scrollToBottom: function _scrollToBottom() {
      var chatList = document.querySelector('.main-section');

      if (chatList) {
        this.scrollToBottomTask = Ember.run.next(function () {
          chatList.scrollTop = chatList.scrollHeight;
        });
      }
    },
    _scrollToBottomSmooth: function _scrollToBottomSmooth() {
      var chatList = document.querySelector('.main-section');

      if (chatList) {
        this.scrollToBottomTask = Ember.run.next(function () {
          chatList.scrollTo({
            top: chatList.scrollHeight,
            behavior: 'smooth'
          });
        });
      }
    }
  });
});