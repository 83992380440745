define('xchatter-webapp/mixins/route/authenticated-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    parseService: Ember.inject.service('parse'),

    beforeModel: function beforeModel() {
      this._transitionIfUserNotAuthenticated();
      this._super.apply(this, arguments);
    },
    _transitionIfUserNotAuthenticated: function _transitionIfUserNotAuthenticated() {
      var currentUser = this.get('parseService.user').current();

      if (!currentUser) {
        var currentPath = window.location.pathname.slice(1);
        var isAuthPages = ['login', 'new-sign-up'].includes(currentPath);

        if (!isAuthPages) this.transitionTo('login');
      }
    }
  });
});