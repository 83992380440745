define('xchatter-webapp/validators/birthday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateBirthdayFormat = validateBirthdayFormat;
  function validateBirthdayFormat(opts) {
    return function (key, newValue, oldValue, changes, content) {
      var regex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (regex.test(newValue)) {
        return true;
      } else {
        return 'Invalid birthday format. Please use the format "DD/MM/YYYY".';
      }
    };
  }
});