define('xchatter-webapp/mixins/route/unauthenticated-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sessionService: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this._transitionIfUserAuthenticated();
    },
    _transitionIfUserAuthenticated: function _transitionIfUserAuthenticated() {
      var currentUser = this.get('sessionService.currentUser');

      if (!!currentUser) {
        var currentPath = window.location.pathname.slice(1);
        var isAuthPages = ['login', 'forgot-password', 'sign-up', 'set-password'].includes(currentPath);
        if (isAuthPages) this.transitionTo('feed');
      }
    }
  });
});