define('xchatter-webapp/components/country-code-select-input-with-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isSelectInputOptionsMenuOpen: false,

    svgIconComponentName: Ember.computed('selectedCountryCode.name', function () {
      var countryName = Ember.String.dasherize(Ember.get(this, 'selectedCountryCode.name'));
      return 'svg/countries/' + countryName + '-flag';
    }),

    actions: {
      toggleSelectInputOptionsMenu: function toggleSelectInputOptionsMenu() {
        this.toggleProperty('isSelectInputOptionsMenuOpen');
      },
      selectItem: function selectItem(countryCode) {
        this.set('selectedCountryCode', countryCode);
        this.send('toggleSelectInputOptionsMenu');
      }
    }
  });
});