define('xchatter-webapp/services/speech', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    recognition: null,
    isListening: false,
    resultCallback: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      this.set('recognition', new SpeechRecognition());
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = function (event) {
        return _this._onResult(event);
      };
      this.recognition.onerror = function (event) {
        return _this._onError(event);
      };
    },
    startListening: function startListening(resultCallback) {
      this.set('isListening', true);
      this.set('resultCallback', resultCallback);
      this.get('recognition').start();
    },
    stopListening: function stopListening() {
      this.set('isListening', false);
      this.get('recognition').stop();
    },
    isSpeechRecognitionSupported: function isSpeechRecognitionSupported() {
      var hasSpeechRecognition = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
      return hasSpeechRecognition;
    },
    isSpeechSynthesisSupported: function isSpeechSynthesisSupported() {
      var hasSpeechSynthesis = 'speechSynthesis' in window;
      return hasSpeechSynthesis;
    },
    _onResult: function _onResult(event) {
      if (this.get('resultCallback')) {
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.get('resultCallback')(event.results[i][0].transcript);
          }
        }
      }
    },
    _onError: function _onError(event) {
      console.error('Speech recognition error', event.error);
      return event;
    }
  });
});