define('xchatter-webapp/components/settings/subscriptions-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isSubscriptionOptionsActionSheetOpen: false,
    isCancelSubscriptionActionSheetOpen: false,
    isLoading: false,

    actions: {
      openSubscriptionOptionsActionSheet: function openSubscriptionOptionsActionSheet() {
        this.set('isSubscriptionOptionsActionSheetOpen', true);
      },
      closeSubscriptionOptionsActionSheet: function closeSubscriptionOptionsActionSheet() {
        this.set('isSubscriptionOptionsActionSheetOpen', false);
      },
      openCancelSubscriptionActionSheet: function openCancelSubscriptionActionSheet() {
        this.set('isSubscriptionOptionsActionSheetOpen', false);
        this.set('isCancelSubscriptionActionSheetOpen', true);
      },
      closeCancelSubscriptionActionSheet: function closeCancelSubscriptionActionSheet() {
        this.set('isCancelSubscriptionActionSheetOpen', false);
      },
      cancelSubscription: function cancelSubscription() {
        this.set('isCancelSubscriptionActionSheetOpen', false);
      }
    }
  });
});