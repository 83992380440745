define('xchatter-webapp/components/video-playback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    videoId: Ember.computed('index', function () {
      if (this.get("forPostModal")) {
        return 'videoPostModal' + this.get('index');
      } else {
        return 'video' + this.get('index');
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var video = this.element.children[0];
      video.muted = true;
    }
  });
});