define('xchatter-webapp/components/country-code-select-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    svgIconComponentName: Ember.computed('countryCode.name', function () {
      var countryName = Ember.String.dasherize(Ember.get(this, 'countryCode.name'));
      return 'svg/countries/' + countryName + '-flag';
    })
  });
});