define('xchatter-webapp/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    mobile: '(max-width: 767px)',
    mobilePortrait: '(max-width: 767px) and (orientation: portrait)',
    mobileLandscape: '(max-width: 767px) and (orientation: landscape)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    tabletPortrait: '(min-width: 768px) and (max-width: 991px) and (orientation: portrait)',
    tabletLandscape: '(min-width: 768px) and (max-width: 991px) and (orientation: landscape)',
    mobileAndTablet: '(max-width: 991px)',
    desktop: '(min-width: 992px) and (max-width: 1200px)',
    desktopPortrait: '(min-width: 992px) and (orientation: portrait)',
    desktopLandscape: '(min-width: 992px) and (orientation: landscape)',
    largeScreen: '(min-width: 1201px)',
    largeScreenPortrait: '(min-width: 1201px) and (orientation: portrait)',
    largeScreenLandscape: '(min-width: 1201px) and (orientation: landscape)',
    desktopAndLargeScreen: '(min-width: 992px)'
  };
});