define('xchatter-webapp/services/screen-size', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    isDesktop: Ember.computed(function () {
      return window.innerWidth >= 1024;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      window.addEventListener('resize', this.updateScreenSize.bind(this));
    },
    updateScreenSize: function updateScreenSize() {
      var isDesktop = window.innerWidth >= 1024;
      this.set('isDesktop', isDesktop);
      this.trigger('screenSizeChanged', isDesktop);
    }
  });
});