define('xchatter-webapp/services/notification', ['exports', 'ember-cli-notifications/services/notification-messages-service'], function (exports, _notificationMessagesService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _notificationMessagesService.default.extend({
    defaultClearDuration: 1500,
    defaultAutoClear: true,

    error: function error(message, op) {
      var options = this._getOptions(op);
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.type = 'error';
      options.message = message;
      options.clearDuration = 10000;
      return this.addNotification(options);
    },
    success: function success(message, op) {
      var options = this._getOptions(op);
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.type = 'success';
      options.message = message;
      return this.addNotification(options);
    },
    info: function info(message, op) {
      var options = this._getOptions(op);
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.type = 'info';
      options.message = message;
      return this.addNotification(options);
    },
    warning: function warning(message, op) {
      var options = this._getOptions(op);
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.type = 'warning';
      options.message = message;
      options.clearDuration = 10000;
      return this.addNotification(options);
    },
    _getOptions: function _getOptions(option) {
      return option ? option : {};
    }
  });
});