define('xchatter-webapp/utils/file', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortenFilename = shortenFilename;
  exports.replaceSpecialCharacters = replaceSpecialCharacters;
  exports.blobToBase64 = blobToBase64;
  exports.base64ToBlob = base64ToBlob;
  exports.blobToBytes = blobToBytes;
  exports.base64ToBytes = base64ToBytes;
  exports.getFileExtensionFromBlob = getFileExtensionFromBlob;
  exports.getTimeStamp = getTimeStamp;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function shortenFilename(filename) {
    var maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;

    if (filename.length <= maxLength) return filename;
    var extension = filename.split('.').pop();
    var nameWithoutExtension = filename.slice(0, -(extension.length + 1));
    var shortenedName = nameWithoutExtension.slice(0, maxLength - extension.length - 1);
    return shortenedName + '.' + extension;
  }

  function replaceSpecialCharacters(fileName) {
    return fileName.replace(/[/,·:*?"<>|]/g, '_');
  }

  function blobToBase64(blob) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onloadend = function () {
        return resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  function base64ToBlob(base64) {
    var mimeType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'application/octet-stream';

    // Decode the base64 data to a binary characters
    var byteCharacters = window.atob(base64);

    // Convert each character to its byte value
    var byteArray = new Uint8Array([].concat(_toConsumableArray(byteCharacters)).map(function (char) {
      return char.charCodeAt(0);
    }));

    return new Blob([byteArray], { type: mimeType });
  }

  function blobToBytes(blob) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onloadend = function () {
        var arrayBuffer = reader.result;
        var uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsArrayBuffer(blob);
    });
  }

  function base64ToBytes(base64) {
    // Decode the base64 data to a binary characters
    var byteCharacters = window.atob(base64);

    // Convert the binary characters to a Uint8Array
    var bytes = new Uint8Array([].concat(_toConsumableArray(byteCharacters)).map(function (char) {
      return char.charCodeAt(0);
    }));

    return bytes;
  }

  function getFileExtensionFromBlob(blob) {
    var mimeTypesMap = {
      'audio/mpeg': 'mp3',
      'audio/ogg': 'ogg',
      'audio/wav': 'wav'
    };

    return mimeTypesMap[blob.type] || 'unknown';
  }

  function getTimeStamp() {
    return new Date().toISOString().replace(/[:.-]/g, '');
  }
});