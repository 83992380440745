define('xchatter-webapp/validations/new-sign-up-no-email-form', ['exports', 'ember-changeset-validations/validators', 'xchatter-webapp/validators/birthday'], function (exports, _validators, _birthday) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: (0, _validators.validatePresence)(true),
    gender: (0, _validators.validatePresence)(true),
    birthday: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ is: 10 }), (0, _birthday.validateBirthdayFormat)()]
  };
});