define("xchatter-webapp/utils/date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertMMDDYYYYStringToDate = convertMMDDYYYYStringToDate;
  function convertMMDDYYYYStringToDate(dateStr) {
    var month = parseInt(dateStr.substring(0, 2), 10) - 1;
    var day = parseInt(dateStr.substring(3, 5), 10);
    var year = parseInt(dateStr.substring(6, 10), 10);
    var date = new Date(year, month, day);

    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
      console.error("Invalid date");
      return null;
    }

    return date;
  }
});