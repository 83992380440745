define('xchatter-webapp/helpers/changeset-set', ['exports', 'ember-changeset/helpers/changeset-set'], function (exports, _changesetSet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _changesetSet.default;
    }
  });
  Object.defineProperty(exports, 'changesetSet', {
    enumerable: true,
    get: function () {
      return _changesetSet.changesetSet;
    }
  });
});