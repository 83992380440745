define('xchatter-webapp/controllers/chats/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isSettingOpen: false,

    actions: {
      toggleSetting: function toggleSetting() {
        this.toggleProperty('isSettingOpen');
      }
    }
  });
});