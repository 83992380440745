define('xchatter-webapp/app', ['exports', 'xchatter-webapp/resolver', 'ember-load-initializers', 'xchatter-webapp/config/environment', '@amplitude/analytics-browser'], function (exports, _resolver, _emberLoadInitializers, _environment, _analyticsBrowser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  /*
   * Initialize Amplitude
   * @see
   * Amplitude instance is configured to track Page Views, Sessions, File Downloads, Form Interactions.
   * Visit https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-default-events for more details.
   */
  _analyticsBrowser.init('293a67a465b361c6d66f0aa122d40b77', {
    defaultTracking: {
      attribution: true,
      pageViews: true,
      sessions: true,
      fileDownload: false,
      formInteraction: false
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});