define('xchatter-webapp/components/chat-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionService: Ember.inject.service('session'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._scrollToBottom();
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);
      this._scrollToBottom();
    },
    _scrollToBottom: function _scrollToBottom() {
      var chatList = document.querySelector('.main');
      if (chatList) {
        Ember.run.later(this, function () {
          chatList.scrollTop = 0;
        }, 500);
      }
    }
  });
});