define('xchatter-webapp/helpers/amplitudeCustomEvents', ['exports', '@amplitude/analytics-browser'], function (exports, _analyticsBrowser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.amplitudeCustomEvents = undefined;
  var amplitudeCustomEvents = exports.amplitudeCustomEvents = function amplitudeCustomEvents(eventName, eventProperties) {
    _analyticsBrowser.track(eventName, eventProperties);
  };

  exports.default = Ember.Helper.helper(amplitudeCustomEvents);
});