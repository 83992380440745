define('xchatter-webapp/validations/payment-form', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // validatePresence for card is set to false, as the card number is not required for the meantime
    // cardNumber: [validatePresence(false), validateLength({ is: 16 })],
    // cardExpiry: [validatePresence(false), validateLength({ is: 5 })],
    // cardCVC: [validatePresence(false), validateLength({ min: 3, max: 4 })],
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    address: (0, _validators.validatePresence)(true),
    city: (0, _validators.validatePresence)(true),
    state: (0, _validators.validatePresence)(true),
    country: (0, _validators.validatePresence)(true),
    zipCode: [(0, _validators.validatePresence)(true)]
  };
});