define('xchatter-webapp/services/index-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    chatResults: null,
    reversedChatResults: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('chatResults', []);
      this.set('reversedChatResults', []);
    },

    addChatResults: function addChatResults(newResults) {
      this.get('chatResults').pushObjects(newResults);
      var reversedResultsArray = newResults.reverse();
      this.get('reversedChatResults').pushObjects(reversedResultsArray);
    },
    clearAllNow: function clearAllNow() {
      this.set('chatResults', []);
      this.set('reversedChatResults', []);
    }
  });
});