define('xchatter-webapp/components/action-sheets/adult-consent-action-sheet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routerService: Ember.inject.service('router'),

    defaultImage: '/img/female-placeholder.png',
    defaultAvatar: '/img/female-avatar-placeholder.png',

    actions: {
      onImageError: function onImageError(post, event) {
        event.target.src = '/img/female-placeholder.png';
        Ember.set(post, 'hasError', true);
      },
      onAvatarError: function onAvatarError(post, event) {
        event.target.src = '/img/female-avatar-placeholder.png';
        Ember.set(post, 'hasAvatarError', true);
      },
      login: function login() {
        localStorage.setItem('FROM_PROFILE', 'true');
        this.get('routerService').transitionTo('login');
      }
    }
  });
});