define('xchatter-webapp/components/modals/credits-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('credits', [{ id: 1, token: '75', icon: 'svg/money-bag-icon-1', priceWithDiscount: '$8.99', price: '$14.99' }, { id: 2, token: '135', icon: 'svg/money-bag-icon-2', priceWithDiscount: '$14.99', price: '$24.99' }, { id: 3, token: '200', icon: 'svg/money-bag-icon-3', priceWithDiscount: '$21.99', price: '$36.99' }, { id: 4, token: '275', icon: 'svg/money-bag-icon-4', priceWithDiscount: '$29.99', price: '$44.99' }, { id: 5, token: '400', icon: 'svg/money-bag-icon-5', priceWithDiscount: '$39.99', price: '$59.99' }, { id: 6, token: '750', icon: 'svg/money-bag-icon-6', priceWithDiscount: '$69.99', price: '$99.99' }]);
    }
  });
});