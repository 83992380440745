define('xchatter-webapp/services/insta-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    pageResults: null,
    reversedPageResults: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pageResults', []);
      this.set('reversedPageResults', []);
    },

    addPageResults: function addPageResults(newResults) {
      this.get('pageResults').pushObjects(newResults);
      var reversedResultsArray = newResults.reverse();
      this.get('reversedPageResults').pushObjects(reversedResultsArray);
    },
    clearAllNow: function clearAllNow() {
      this.set('pageResults', []);
      this.set('reversedPageResults', []);
    }
  });
});