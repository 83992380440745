define('xchatter-webapp/components/detect-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setScrollListener();
    },
    willDestroyElement: function willDestroyElement() {
      var scrollOnElement = this.get('scrollOnElement') || window;
      scrollOnElement.removeEventListener('scroll', this.scrollHandler);
      this._super.apply(this, arguments);
    },
    setScrollListener: function setScrollListener() {
      var scrollOnElement = this.get('scrollOnElement') || window;
      this.scrollHandler = Ember.run.bind(this, this.handleScroll);
      scrollOnElement.addEventListener('scroll', this.scrollHandler);
    },
    handleScroll: function handleScroll() {
      this.get('handleScroll')();
    }
  });
});