define('xchatter-webapp/services/route-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),

    previousRoute: null,

    add: function add(routeName) {
      this.set('previousRoute', routeName);
    },
    back: function back() {
      var previousRouter = this.get('previousRoute') ? this.get('previousRoute') : 'login';
      this.get('router').transitionTo(previousRouter);
    }
  });
});