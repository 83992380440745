define('xchatter-webapp/routes/index', ['exports', 'xchatter-webapp/mixins/route/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.transitionTo('feed');
    }
  });
});