define('xchatter-webapp/validations/sign-up-form', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    firstName: (0, _validators.validatePresence)(true),
    birthday: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ is: 8 })],
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'phone' })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
    password: (0, _validators.validatePresence)(true)
  };
});