define('xchatter-webapp/helpers/getComputed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getComputed = exports.getComputed = function getComputed(serviceName, propertyName) {
        return Ember.computed(serviceName + '.' + propertyName, function () {
            return this.get(serviceName + '.' + propertyName);
        });
    };

    exports.default = Ember.Helper.helper(getComputed);
});