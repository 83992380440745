define('xchatter-webapp/services/resize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setup: function setup() {
      window.addEventListener('resize', this.get('_onResize').bind(this));
      this.get('_setCustomVHProperty')();
    },
    _onResize: function _onResize() {
      Ember.run.debounce(this, this.get('_setCustomVHProperty'), 350);
    },
    _setCustomVHProperty: function _setCustomVHProperty() {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
  });
});