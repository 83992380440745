define('xchatter-webapp/components/bookmark-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaultImage: '/img/female-placeholder.png',

    actions: {
      onImageError: function onImageError(e) {
        e.target.src = '/img/female-placeholder.png';
      },
      onAvatarError: function onAvatarError(e) {
        e.target.src = '/img/female-avatar-placeholder.png';
      }
    }
  });
});