define('xchatter-webapp/routes/ais/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var data = [{ id: 1, name: 'Samantha', imageURL: '/svg/matches/matches-2.svg' }, { id: 2, name: 'Olivia', imageURL: '/svg/matches/matches-2.svg' }, { id: 3, name: 'Emma', imageURL: '/svg/matches/matches-2.svg' }, { id: 4, name: 'Amelia', imageURL: '/svg/matches/matches-2.svg' }, { id: 5, name: 'Mia', imageURL: '/svg/matches/matches-2.svg' }, { id: 6, name: 'Ava', imageURL: '/svg/matches/matches-2.svg' }, { id: 7, name: 'Sophia', imageURL: '/svg/matches/matches-2.svg' }, { id: 8, name: 'Charlotte', imageURL: '/svg/matches/matches-2.svg' }];

  exports.default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return new Promise(function (resolve) {
        Ember.run.later(_this, function () {
          resolve(data);
        }, 500);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('activeAIProfile', model.get('firstObject'));
    }
  });
});