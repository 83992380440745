define('xchatter-webapp/components/wave-surfer', ['exports', 'wavesurfer.js'], function (exports, _wavesurfer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      try {
        this.waveSurfer = _wavesurfer.default.create({
          container: '#' + this.elementId,
          waveColor: 'violet',
          progressColor: 'purple',
          xhr: {
            cache: "default",
            mode: "cors",
            method: "GET",
            credentials: "include",
            headers: [{ key: "cache-control", value: "no-cache" }, { key: "pragma", value: "no-cache" }]
          }
        });
        // const myAudio = new Audio(this.get('src'));
        // myAudio.crossOrigin = 'anonymous';
        this.waveSurfer.load(this.get('src'));
      } catch (error) {
        console.error(error);
      }
    }
  });
});