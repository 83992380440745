define('xchatter-webapp/controllers/ais/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isAIProfileModalActive: false,
    activeAIProfile: null,

    actions: {
      showAIProfileModal: function showAIProfileModal(aiProfile) {
        this.set('activeAIProfile', aiProfile);
        this.set('isAIProfileModalActive', true);
      }
    }
  });
});