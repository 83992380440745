define('xchatter-webapp/routes/chats/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var data = {
    shopList: [{ id: 1, token: '75', icon: 'svg/money-bag-icon-1', priceWithDiscount: '$14.99', price: '$15.99' }, { id: 2, token: '135', icon: 'svg/money-bag-icon-2', priceWithDiscount: '$24.99', price: '$26.99' }, { id: 3, token: '200', icon: 'svg/money-bag-icon-3', priceWithDiscount: '$34.99', price: '$38.99' }, { id: 4, token: '275', icon: 'svg/money-bag-icon-4', priceWithDiscount: '$44.99', price: '$54.99' }, { id: 5, token: '400', icon: 'svg/money-bag-icon-5', priceWithDiscount: '$59.99', price: '$79.99' }, { id: 6, token: '750', icon: 'svg/money-bag-icon-6', priceWithDiscount: '$99.99', price: '$149.99' }]
  };

  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return new Promise(function (resolve) {
        Ember.run.later(_this, function () {
          resolve(_extends({}, data, { username: params.username }));
        }, 500);
      });
    }
  });
});