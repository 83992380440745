define('xchatter-webapp/services/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service('parse'),
    parseConverterService: Ember.inject.service('parse-converter'),

    create: function create(params) {
      return this.get('parseService.cloud').run('addUserDetailsXchatter', params);
    },
    sendCodeForPhoneNumberOrEmailUpdate: function sendCodeForPhoneNumberOrEmailUpdate(params) {
      return this.get('parseService.cloud').run('sendCodeForPhoneNumberOrEmailUpdate', params);
    },
    verifyCodeXchatterPhoneOrEmailUpdate: function verifyCodeXchatterPhoneOrEmailUpdate(params) {
      return this.get('parseService.cloud').run('verifyCodeXchatterPhoneOrEmailUpdate', params);
    },
    getModelProfile: function getModelProfile(params) {
      var _this = this;

      return this.get('parseService.cloud').run('getModelProfile', params).then(function (response) {
        var modelRes = response.map(function (post) {
          return _this.get('parseConverterService').convertToModelProfile(post);
        });

        return modelRes;
      });
    },
    getModelProfilesForFeed: function getModelProfilesForFeed(params) {
      var _this2 = this;

      return this.get('parseService.cloud').run('getModelProfilesForFeed', params).then(function (response) {
        var modelRes = response.map(function (post) {
          return _this2.get('parseConverterService').convertToModelProfile(post);
        });

        return modelRes;
      });
    },
    getModelProfilesInBulk: function getModelProfilesInBulk(params) {
      var _this3 = this;

      return this.get('parseService.cloud').run('getModelProfilesInBulk', params).then(function (response) {
        var modelRes = response.map(function (post) {
          return _this3.get('parseConverterService').convertToModelProfile(post);
        });

        return modelRes;
      });
    },
    getAllModelProfiles: function getAllModelProfiles(params) {
      var _this4 = this;

      return this.get('parseService.cloud').run('getAllModelProfiles', params).then(function (response) {
        var modelRes = response.map(function (post) {
          return _this4.get('parseConverterService').convertToModelProfile(post);
        });

        return modelRes;
      });
    },
    followOrUnfollowAccount: function followOrUnfollowAccount(params) {
      return this.get('parseService.cloud').run('followOrUnfollowAccount', params);
    },
    initiateAccountDeletion: function initiateAccountDeletion(params) {
      return this.get('parseService.cloud').run('initiateAccountDeletion', params);
    },
    confirmAccountDeletion: function confirmAccountDeletion(params) {
      return this.get('parseService.cloud').run('confirmAccountDeletion', params);
    },
    getConversationsOfFollowedAccounts: function getConversationsOfFollowedAccounts(params) {
      var _this5 = this;

      return this.get('parseService.cloud').run('getConversationsOfFollowedAccounts', params).then(function (response) {
        var followRes = response.map(function (acc) {
          return _this5.get('parseConverterService').convertToFollowed(acc);
        });

        return followRes;
      });
    }
  });
});