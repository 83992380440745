define('xchatter-webapp/routes/live-call-demo', ['exports', 'xchatter-webapp/mixins/route/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    conversationService: Ember.inject.service('conversation'),
    notificationService: Ember.inject.service('notification'),

    model: function model() {
      return this.get('conversationService').createOrFindWebBasedTwilioConversation();
    },


    actions: {
      error: function error(_error) {
        this.get('notificationService').error(_error);
        return true;
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });
});