define('xchatter-webapp/components/lottie-animation', ['exports', 'lottie-web'], function (exports, _lottieWeb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lottie: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var lottieInstance = _lottieWeb.default.loadAnimation({
        container: this.element,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: this.get('src')
      });
      this.set('lottie', lottieInstance);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('lottie').destroy();
    }
  });
});