define('xchatter-webapp/controllers/chats/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sessionService: Ember.inject.service('session'),

    isSettingOpen: false,
    isPurchaseOpen: false,
    isUserTyping: false,
    activePurchase: null,
    chatMessage: null,

    actions: {
      toggleSetting: function toggleSetting() {
        this.toggleProperty('isSettingOpen');
      },
      togglePurchase: function togglePurchase() {
        this.toggleProperty('isPurchaseOpen');
      },
      startTyping: function startTyping(value) {
        this.set('isUserTyping', true);
        if (value.length === 1) this.send('scrollToBottom');
        this.set('chatMessage', value);
      },
      stopTyping: function stopTyping() {
        this.set('isUserTyping', false);
      },
      scrollToBottom: function scrollToBottom() {
        Ember.run.later(this, function () {
          var chatList = document.querySelector('.main');
          chatList.scrollTo({ top: -10, behavior: 'smooth' });
        }, 500);
      },
      setActivePurchase: function setActivePurchase(purchase) {
        this.set('activePurchase', purchase);
      },
      submit: function submit() {
        try {
          this.send('togglePurchase');
          this.send('toggleSetting');
          this.transitionToRoute('payment.success');
        } catch (error) {
          return error;
        }
      },
      sendMessage: function sendMessage(chatMessage) {}
    }
  });
});