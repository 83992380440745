define('xchatter-webapp/components/swiper-js', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    swiper: null,
    direction: 'horizontal',
    speed: 100,
    slidesPerView: 1,
    spaceBetween: 1,
    initialSlide: "0",

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var swiper = new window.Swiper('.swiper', {
        initialSlide: this.get('initialSlide'),
        slidesPerView: this.get('slidesPerView'),
        spaceBetween: this.get('spaceBetween'),
        speed: this.get('speed'),
        direction: this.get('direction'),
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        on: {
          reachBeginning: function reachBeginning() {
            // https://swiperjs.com/swiper-api#event-reachBeginning
            // Event will be fired when Swiper reach its beginning (initial position)
          },
          reachEnd: function reachEnd() {
            // https://swiperjs.com/swiper-api#event-reachEnd
            // Event will be fired when Swiper reach last slide
            _this.send('loadMore');
          }
          // slideChange: () => {
          //   const activeIndex = swiper.activeIndex;
          //   this.sendAction('onSlideChange', activeIndex);
          // },
        },
        // Enable scrolling to the next slide on click
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });
      this.set('swiper', swiper);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('swiper').destroy();
    },


    actions: {
      loadMore: function loadMore() {
        // https://swiperjs.com/swiper-api#method-swiper-update
        // You should call it after you add/remove slides manually, or after you hide/show it,
        // or do any custom DOM modifications with Swiper
        // this.get('swiper').update();
      }
    }
  });
});