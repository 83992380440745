define('xchatter-webapp/services/plans', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service('parse'),
    parseConverterService: Ember.inject.service('parse-converter'),

    getSubscriptionPlans: function getSubscriptionPlans(params) {
      var _this = this;

      return this.get('parseService.cloud').run('getSubscriptionPlans', params).then(function (response) {
        var planRes = response.map(function (plan) {
          return _this.get('parseConverterService').convertToPlan(plan);
        });

        return planRes;
      });
    },
    getActiveSubscriptions: function getActiveSubscriptions() {
      var _this2 = this;

      return this.get('parseService.cloud').run('getActiveSubscriptions').then(function (response) {
        var subsRes = response.map(function (sub) {
          return _this2.get('parseConverterService').convertToSubscriptions(sub);
        });

        return subsRes;
      });
    },
    getToken: function getToken(params) {
      return this.get('parseService.cloud').run('getPaymentForm', params).then(function (response) {
        var token = response;
        return token;
      });
    },
    createSubscriptionXChatter: function createSubscriptionXChatter(params) {
      return this.get('parseService.cloud').run('createSubscriptionXChatter', params).then(function (response) {
        var message = response;
        return message;
      });
    },
    cancelSubscription: function cancelSubscription(params) {
      return this.get('parseService.cloud').run('cancelSubscription', params).then(function (response) {
        var message = response;
        return message;
      });
    },
    upgradeSubscription: function upgradeSubscription(params) {
      return this.get('parseService.cloud').run('upgradeSubscription', params).then(function (response) {
        var message = response;
        return message;
      });
    }
  });
});