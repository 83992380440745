define('xchatter-webapp/services/parse-converter', ['exports', 'libphonenumber-js'], function (exports, _libphonenumberJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    convertToChat: function convertToChat(item) {
      var myChat = {};

      var model = item.get('model');
      if (model == undefined) {
        myChat.model = '';
      } else {
        myChat.model = model;
      }

      var prompt = item.get('prompt');
      if (prompt == undefined) {
        myChat.prompt = '';
      } else {
        myChat.prompt = prompt;
      }

      var temperature = item.get('temperature');
      if (temperature == undefined) {
        myChat.temperature = 0;
      } else {
        myChat.temperature = temperature;
      }

      var prompt_tokens = item.get('prompt_tokens');
      if (prompt_tokens == undefined) {
        myChat.prompt_tokens = 0;
      } else {
        myChat.prompt_tokens = prompt_tokens;
      }

      var completion_tokens = item.get('completion_tokens');
      if (completion_tokens == undefined) {
        myChat.completion_tokens = 0;
      } else {
        myChat.completion_tokens = completion_tokens;
      }

      var total_tokens = item.get('total_tokens');
      if (total_tokens == undefined) {
        myChat.total_tokens = 0;
      } else {
        myChat.total_tokens = total_tokens;
      }

      var max_tokens = item.get('max_tokens');
      if (max_tokens == undefined) {
        myChat.max_tokens = 0;
      } else {
        myChat.max_tokens = max_tokens;
      }

      var n = item.get('n');
      if (n == undefined) {
        myChat.n = 0;
      } else {
        myChat.n = n;
      }

      var presence_penalty = item.get('presence_penalty');
      if (presence_penalty == undefined) {
        myChat.presence_penalty = 0;
      } else {
        myChat.presence_penalty = presence_penalty;
      }

      var frequency_penalty = item.get('frequency_penalty');
      if (frequency_penalty == undefined) {
        myChat.frequency_penalty = 0;
      } else {
        myChat.frequency_penalty = frequency_penalty;
      }

      var chat_id = item.get('chat_id');
      if (chat_id == undefined) {
        myChat.chat_id = '';
      } else {
        myChat.chat_id = chat_id;
      }

      var created = item.get('created');
      if (created == undefined) {
        myChat.created = 0;
      } else {
        myChat.created = created;
      }

      var response = item.get('response');
      if (response == undefined) {
        myChat.response = '';
      } else {
        myChat.response = response;
      }

      var object_type = item.get('object_type');
      if (object_type == undefined) {
        myChat.object_type = '';
      } else {
        myChat.object_type = object_type;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        myChat.createdAt = '';
      } else {
        myChat.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myChat.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        myChat.updatedAt = '';
        myChat.shortUpdatedAt = '';
      } else {
        myChat.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myChat.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myChat.objectId = '';
      } else {
        myChat.objectId = objectId;
      }

      var user = item.get('user');
      if (user == undefined) {
        myChat.user = {};
      } else {
        myChat.user = this.convertToUser(user);
      }

      return myChat;
    },


    convertToUser: function convertToUser(item) {
      var myUser = {};
      var thisUser = item;

      var username = thisUser.get('username');
      if (username == undefined) {
        myUser.username = '';
      } else {
        myUser.username = username.toLowerCase();
      }

      var email = thisUser.get('email');
      if (email == undefined) {
        myUser.email = '';
      } else {
        myUser.email = email.toLowerCase();
      }

      var isTester = thisUser.get('isTester');
      if (isTester == undefined) {
        myUser.isTester = false;
      } else {
        myUser.isTester = isTester;
      }

      var createdAt = thisUser.createdAt;
      if (createdAt == undefined) {
        myUser.createdAt = '';
      } else {
        myUser.createdAt = createdAt;
      }

      var objectId = thisUser.id;
      if (objectId == undefined) {
        myUser.objectId = '';
      } else {
        myUser.objectId = objectId;
      }

      var disableTrainingData = thisUser.get('disableTrainingData');
      if (disableTrainingData == undefined) {
        myUser.disableTrainingData = false;
      } else {
        myUser.disableTrainingData = disableTrainingData;
      }

      return myUser;
    },

    convertToPage: function convertToPage(item) {
      var myPage = {};

      var name = item.get('name');
      if (name == undefined) {
        myPage.name = '';
      } else {
        myPage.name = name;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        myPage.createdAt = '';
      } else {
        myPage.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myPage.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        myPage.updatedAt = '';
        myPage.shortUpdatedAt = '';
      } else {
        myPage.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myPage.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myPage.objectId = '';
      } else {
        myPage.objectId = objectId;
      }

      var user = item.get('user');
      if (user == undefined) {
        myPage.user = {};
      } else {
        myPage.user = this.convertToUser(user);
      }

      return myPage;
    },


    convertToAccount: function convertToAccount(item) {
      var myAccount = {};

      myAccount.phoneNotificationNumber = this._getStringValue(item, 'phoneNotificationNumber');
      myAccount.phoneNotificationNumberFormatted = myAccount.phoneNotificationNumber ? (0, _libphonenumberJs.default)('+' + myAccount.phoneNotificationNumber) : '';
      myAccount.plan = this._getStringValue(item, 'plan');
      myAccount.city = this._getStringValue(item, 'city');
      myAccount.name = this._getStringValue(item, 'name');
      myAccount.emailNotificationAddress = this._getStringValue(item, 'emailNotificationAddress');
      myAccount.zipCode = this._getStringValue(item, 'zipCode');
      myAccount.state = this._getStringValue(item, 'state');
      myAccount.stateAddress = this._getStringValue(item, 'stateAddress');
      myAccount.country = this._getStringValue(item, 'country');
      myAccount.website = this._getStringValue(item, 'website');
      myAccount.entityType = this._getStringValue(item, 'entityType');

      myAccount.averageNumOfMessagesConv = this._getNumberValue(item, 'averageNumOfMessagesConv');
      myAccount.numPastMessages = this._getNumberValue(item, 'numPastMessages');
      myAccount.estNumOfMessagesConv = this._getNumberValue(item, 'estNumOfMessagesConv');
      myAccount.numberOfTokens = this._getNumberValue(item, 'numberOfTokens');

      myAccount.receiveNotifications = this._getBooleanValue(item, 'receiveNotifications');
      myAccount.active = this._getBooleanValue(item, 'active');
      myAccount.automate = this._getBooleanValue(item, 'automate');

      myAccount.modelId = this._getStringValue(item, 'modelId');
      myAccount.taxId = this._getStringValue(item, 'taxId');
      myAccount.userId = this._getStringValue(item, 'userId');

      myAccount.createdAt = this._getDateValue(item, 'createdAt');
      myAccount.shortDate = this._getShortDateValue(item, 'createdAt');
      myAccount.updatedAt = this._getDateValue(item, 'updatedAt');
      myAccount.shortUpdatedAt = this._getShortDateValue(item, 'updatedAt');
      myAccount.cycleStartDate = this._getDateValue(item, 'cycleStartDate');
      myAccount.cycleStartShortDate = this._getShortDateValue(item, 'cycleStartDate');
      myAccount.monthlyCycle = myAccount.cycleStartDate ? this._calculateRemainingDays(myAccount.cycleStartDate) : '';
      myAccount.renewalDate = myAccount.cycleStartDate ? this._calculateRenewalDate(myAccount.cycleStartDate) : '';

      myAccount.accountImage = this._getStringValue(item, 'accountImage');

      myAccount.startDate = this._getDateValue(item, 'startDate');
      myAccount.startDateShortDate = this._getShortDateValue(item, 'startDate');

      myAccount.objectId = item.id;

      return myAccount;
    },

    convertToChatFunc: function convertToChatFunc(item) {
      var myChat = {};
      var thisUser = item;

      var model = thisUser.get('model');
      if (model == undefined) {
        myChat.model = '';
      } else {
        myChat.model = model;
      }

      var prompt = thisUser.get('prompt');
      if (prompt == undefined) {
        myChat.prompt = '';
      } else {
        myChat.prompt = prompt;
      }

      var temperature = thisUser.get('temperature');
      if (temperature == undefined) {
        myChat.temperature = 0;
      } else {
        myChat.temperature = temperature;
      }

      var prompt_tokens = thisUser.get('prompt_tokens');
      if (prompt_tokens == undefined) {
        myChat.prompt_tokens = 0;
      } else {
        myChat.prompt_tokens = prompt_tokens;
      }

      var completion_tokens = thisUser.get('completion_tokens');
      if (completion_tokens == undefined) {
        myChat.completion_tokens = 0;
      } else {
        myChat.completion_tokens = completion_tokens;
      }

      var total_tokens = thisUser.get('total_tokens');
      if (total_tokens == undefined) {
        myChat.total_tokens = 0;
      } else {
        myChat.total_tokens = total_tokens;
      }

      var max_tokens = thisUser.get('max_tokens');
      if (max_tokens == undefined) {
        myChat.max_tokens = 0;
      } else {
        myChat.max_tokens = max_tokens;
      }

      var n = thisUser.get('n');
      if (n == undefined) {
        myChat.n = 0;
      } else {
        myChat.n = n;
      }

      var presence_penalty = thisUser.get('presence_penalty');
      if (presence_penalty == undefined) {
        myChat.presence_penalty = 0;
      } else {
        myChat.presence_penalty = presence_penalty;
      }

      var frequency_penalty = thisUser.get('frequency_penalty');
      if (frequency_penalty == undefined) {
        myChat.frequency_penalty = 0;
      } else {
        myChat.frequency_penalty = frequency_penalty;
      }

      var chat_id = thisUser.get('chat_id');
      if (chat_id == undefined) {
        myChat.chat_id = '';
      } else {
        myChat.chat_id = chat_id;
      }

      var created = thisUser.get('created');
      if (created == undefined) {
        myChat.created = 0;
      } else {
        myChat.created = created;
      }

      var response = thisUser.get('response');
      if (response == undefined) {
        myChat.response = '';
      } else {
        myChat.response = response;
      }

      var object_type = thisUser.get('object_type');
      if (object_type == undefined) {
        myChat.object_type = '';
      } else {
        myChat.object_type = object_type;
      }

      var createdAt = thisUser.createdAt;
      if (createdAt == undefined) {
        myChat.createdAt = '';
      } else {
        myChat.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myChat.shortDate = shortDate;
      }

      var updatedAt = thisUser.updatedAt;
      if (createdAt == updatedAt) {
        myChat.updatedAt = '';
        myChat.shortUpdatedAt = '';
      } else {
        myChat.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myChat.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = thisUser.id;
      if (objectId == undefined) {
        myChat.objectId = '';
      } else {
        myChat.objectId = objectId;
      }

      var user = thisUser.get('user');
      if (user == undefined) {
        myChat.user = {};
      } else {
        myChat.user = this.convertToUser(user);
      }

      return myChat;
    },

    convertToTwilioConversation: function convertToTwilioConversation(item) {
      var myTwilioConversation = {};

      var account = item.get('account');
      if (account == undefined) {
        myTwilioConversation.account = {};
      } else {
        myTwilioConversation.account = this.convertToAccount(item.get('account'));
      }

      var lastChat = item.get('lastChat');
      if (lastChat == undefined) {
        myTwilioConversation.lastChat = {};
      } else {
        myTwilioConversation.lastChat = this.convertToChatFunc(item.get('lastChat'));
      }

      myTwilioConversation.fromCountry = this._getStringValue(item, 'fromCountry');
      myTwilioConversation.state = this._getStringValue(item, 'state');
      myTwilioConversation.fromNumber = this._getStringValue(item, 'fromNumber');
      myTwilioConversation.fromCity = this._getStringValue(item, 'fromCity');

      myTwilioConversation.lastMessageDate = this._getDateValue(item, 'lastMessageDate');
      myTwilioConversation.shortLastMessageDate = this._getShortDateValue(item, 'lastMessageDate');

      myTwilioConversation.paused = this._getBooleanValue(item, 'paused');
      myTwilioConversation.ignore = this._getBooleanValue(item, 'ignore');
      myTwilioConversation.flagged = this._getBooleanValue(item, 'flagged');

      myTwilioConversation.status = myTwilioConversation.state.toLowerCase();

      myTwilioConversation.createdAt = this._getDateValue(item, 'createdAt');
      myTwilioConversation.shortDate = this._getShortDateValue(item, 'createdAt');

      myTwilioConversation.updatedAt = this._getDateValue(item, 'updatedAt');
      myTwilioConversation.shortUpdatedAt = this._getShortDateValue(item, 'updatedAt');
      myTwilioConversation.messagesDateFormat = this._messagesDateFormat(myTwilioConversation.updatedAt);

      myTwilioConversation.objectId = item.id;

      myTwilioConversation.automate = this._getBooleanValue(item, 'automate');
      myTwilioConversation.optOut = this._getBooleanValue(item, 'optOut');
      myTwilioConversation.numberOfMessages = this._getNumberValue(item, 'numberOfMessages');
      myTwilioConversation.tags = this._getArrayValue(item, 'tags');
      myTwilioConversation.notes = this._getStringValue(item, 'notes');

      var isInstagram = item.get('isInstagram');
      if (isInstagram == undefined) {
        myTwilioConversation.isInstagram = false;
      } else {
        myTwilioConversation.isInstagram = isInstagram;
      }

      var first_name = item.get('first_name');
      if (first_name == undefined) {
        myTwilioConversation.first_name = '';
        myTwilioConversation.hasFirstName = false;
      } else {
        myTwilioConversation.first_name = first_name;
        myTwilioConversation.hasFirstName = true;
      }

      var last_name = item.get('last_name');
      if (last_name == undefined) {
        myTwilioConversation.last_name = '';
      } else {
        myTwilioConversation.last_name = last_name;
      }

      var instagramName = item.get('instagramName');
      if (instagramName == undefined) {
        myTwilioConversation.instagramName = '';
      } else {
        myTwilioConversation.instagramName = instagramName;
      }

      var instagramId = item.get('instagramId');
      if (instagramId == undefined) {
        myTwilioConversation.instagramId = '';
      } else {
        myTwilioConversation.instagramId = instagramId;
      }

      myTwilioConversation.accountId = this._getBooleanValue(item, 'accountId');

      myTwilioConversation.isFollowing = this._getBooleanValue(item, 'isFollowing');
      myTwilioConversation.isSubscribed = this._getBooleanValue(item, 'isSubscribed');
      myTwilioConversation.subscriptionEndDate = this._getDateValue(item, 'subscriptionEndDate');

      return myTwilioConversation;
    },


    convertToImage: function convertToImage(item) {
      var image = {};

      image.prompt = this._getStringValue(item, 'prompt');
      image.image_size = this._getNumberValue(item, 'image_size');
      image.meta = this._getStringValue(item, 'meta');
      image.caption = this._getStringValue(item, 'caption');
      image.image_url = this._getStringValue(item, 'image_url');
      image.createdAt = this._getDateValue(item, 'createdAt');
      image.shortDate = this._getShortDateValue(item, 'createdAt');
      image.updatedAt = this._getDateValue(item, 'updatedAt');
      image.shortUpdatedAt = this._getShortDateValue(item, 'updatedAt');
      image.status = this._getStringValue(item, 'status');
      image.imageFile = this._getStringValue(item, 'imageFile');
      image.tokenValue = this._getNumberValue(item, 'tokenValue');
      image.adult = this._getBooleanValue(item, 'adult');

      image.objectId = item.id;

      return image;
    },

    convertToPostFunc: function convertToPostFunc(item) {
      var post = {};

      var objectId = item.id;
      if (objectId == undefined) {
        post.objectId = '';
      } else {
        post.objectId = objectId;
      }

      post.text = this._getStringValue(item, 'text');

      var account = item.get('account');
      if (account == undefined) {
        post.account = {};
      } else {
        post.account = this.convertToAccount(account);
        // post.account = account;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        post.createdAt = '';
      } else {
        post.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        post.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        post.updatedAt = '';
      } else {
        post.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        post.shortDateUpdated = shortDateUpdated;
      }

      post.imageFile = this._getStringValue(item, 'imageFile');
      post.videoFile = this._getStringValue(item, 'videoFile');
      post.blurredImage = this._getStringValue(item, 'blurredImage');

      var image = item.get('image');
      if (image == undefined) {
        post.image = '';
      } else {
        post.image = this.convertToImage(image);
      }

      post.numberOfComments = this._getNumberValue(item, 'numberOfComments');
      post.numberOfLikes = this._getNumberValue(item, 'numberOfLikes');
      post.isAdult = this._getBooleanValue(item, 'isAdult');

      return post;
    },

    convertToPost: function convertToPost(item) {
      var post = {};

      var objectId = item.id;
      if (objectId == undefined) {
        post.objectId = '';
      } else {
        post.objectId = objectId;
      }

      post.text = this._getStringValue(item, 'text');

      var account = item.get('account');
      if (account == undefined) {
        post.account = {};
      } else {
        post.account = this.convertToAccount(account);
        // post.account = account;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        post.createdAt = '';
      } else {
        post.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        post.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        post.updatedAt = '';
        post.timeAgo = '';
      } else {
        post.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        post.shortDateUpdated = shortDateUpdated;
        post.timeAgo = this._getTimeAgo(updatedAt);
      }

      post.imageFile = this._getStringValue(item, 'imageFile');
      post.videoFile = this._getStringValue(item, 'videoFile');
      post.blurredImage = this._getStringValue(item, 'blurredImage');

      var image = item.get('image');
      if (image == undefined) {
        post.image = '';
      } else {
        post.image = this.convertToImage(image);
      }

      post.numberOfComments = this._getNumberValue(item, 'numberOfComments');
      post.numberOfLikes = this._getNumberValue(item, 'numberOfLikes');
      post.isAdult = this._getBooleanValue(item, 'isAdult');

      return post;
    },
    convertToBookmark: function convertToBookmark(item) {
      var bookmark = {};

      var objectId = item.id;
      if (objectId == undefined) {
        bookmark.objectId = '';
      } else {
        bookmark.objectId = objectId;
      }

      var account = item.get('account');
      if (account == undefined) {
        bookmark.account = {};
      } else {
        bookmark.account = this.convertToAccount(account);
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        bookmark.createdAt = '';
      } else {
        bookmark.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        bookmark.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        bookmark.updatedAt = '';
      } else {
        bookmark.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        bookmark.shortDateUpdated = shortDateUpdated;
      }

      bookmark.isPurchase = this._getBooleanValue(item, 'isPurchase');
      bookmark.type = this._getStringValue(item, 'type');
      bookmark.postId = this._getStringValue(item, 'postId');

      var post = item.get('post');
      if (post == undefined) {
        bookmark.post = {};
      } else {
        bookmark.post = this.convertToPostFunc(post);
      }

      return bookmark;
    },
    convertToComment: function convertToComment(commentResponse) {
      var comment = {};

      comment.objectId = commentResponse.id;
      comment.name = this._getStringValue(commentResponse, 'publicName');
      comment.text = this._getStringValue(commentResponse, 'text');
      comment.numberOfLikes = this._getNumberValue(commentResponse, 'numberOfLikes');

      comment.postId = this._getStringValue(commentResponse, 'postId');
      comment.post = this._getObjValue(commentResponse, 'post');
      comment.userId = this._getStringValue(commentResponse, 'userId');
      comment.user = this._getObjValue(commentResponse, 'user');
      comment.accountId = this._getStringValue(commentResponse, 'accountId');
      comment.account = this._getObjValue(commentResponse, 'account');

      comment.createdAt = this._getDateValue(commentResponse, 'createdAt');
      comment.updatedAt = this._getDateValue(commentResponse, 'updatedAt');

      // if the comment is a reply, it will have a parentPostCommentId
      comment.parentPostCommentId = this._getStringValue(commentResponse, 'parentPostCommentId');

      // added is isLiked property to the comment
      comment.isLiked = this._getBooleanValue(commentResponse, 'isLiked');

      return comment;
    },
    convertToPostLike: function convertToPostLike(item) {
      var postLike = {};

      var objectId = item.id;
      if (objectId == undefined) {
        postLike.objectId = '';
      } else {
        postLike.objectId = objectId;
      }

      var post = item.get('post');
      if (post == undefined) {
        postLike.post = {};
      } else {
        postLike.post = this.convertToPostFunc(post);
      }

      var account = item.get('account');
      if (account == undefined) {
        postLike.account = {};
      } else {
        postLike.account = this.convertToAccount(account);
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        postLike.createdAt = '';
      } else {
        postLike.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        postLike.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        postLike.updatedAt = '';
      } else {
        postLike.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        postLike.shortDateUpdated = shortDateUpdated;
      }

      return postLike;
    },
    convertToMessage: function convertToMessage(messageResponse) {
      var message = {};

      var account = this._getObjValue(messageResponse, 'account');

      message.objectId = messageResponse.id;
      message.account = this.convertToAccount(account);
      message.prompt = this._getStringValue(messageResponse, 'prompt');
      message.response = this._getStringValue(messageResponse, 'response');
      message.mp3AudioFile = this._getStringValue(messageResponse, 'mp3AudioFile');

      return message;
    },
    convertToModelProfile: function convertToModelProfile(item) {
      var profile = {};

      var objectId = item.id;
      if (objectId == undefined) {
        profile.objectId = '';
      } else {
        profile.objectId = objectId;
      }

      profile.about = this._getStringValue(item, 'about');
      profile.bannerImage = this._getStringValue(item, 'bannerImage');
      profile.profileImage = this._getStringValue(item, 'profileImage');
      profile.description = this._getStringValue(item, 'description');
      profile.instagramLink = this._getStringValue(item, 'instagramLink');
      profile.name = this._getStringValue(item, 'name');
      profile.urlName = this._getStringValue(item, 'urlName');
      profile.accountId = this._getStringValue(item, 'accountId');

      var firstName = this._getStringValue(item, 'name');
      firstName = firstName.split(' ')[0];
      profile.firstName = firstName;

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        profile.createdAt = '';
      } else {
        profile.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        profile.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        profile.updatedAt = '';
      } else {
        profile.updatedAt = updatedAt;
        profile.shortDateUpdated = updatedAt.toLocaleDateString();
      }

      return profile;
    },
    convertToPlan: function convertToPlan(item) {
      var plan = {};

      plan.objectId = item.id;
      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        plan.createdAt = '';
      } else {
        plan.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        plan.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        plan.updatedAt = '';
      } else {
        plan.updatedAt = updatedAt;
        plan.shortDateUpdated = updatedAt.toLocaleDateString();
      }

      plan.description = this._getStringValue(item, 'description');
      plan.name = this._getStringValue(item, 'name');
      plan.price = this._getNumberValue(item, 'price');
      plan.features = this._getNumberValue(item, 'features');

      return plan;
    },
    convertToSubscriptions: function convertToSubscriptions(item) {
      var subscription = {};

      var objectId = item.id;
      if (objectId == undefined) {
        subscription.objectId = '';
      } else {
        subscription.objectId = objectId;
      }

      var account = item.get('account');
      if (account == undefined) {
        subscription.account = {};
      } else {
        subscription.account = this.convertToAccount(account);
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        subscription.createdAt = '';
      } else {
        subscription.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        subscription.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        subscription.updatedAt = '';
      } else {
        subscription.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        subscription.shortDateUpdated = shortDateUpdated;
      }

      var expirationDate = item.get('expirationDate');
      if (expirationDate == undefined) {
        subscription.expirationDate = '';
      } else {
        subscription.expirationDate = expirationDate;
        var shortExpirationDate = expirationDate.toLocaleDateString();
        subscription.shortExpirationDate = shortExpirationDate;
      }

      subscription.durationDays = this._getNumberValue(item, 'durationDays');
      subscription.isActive = this._getBooleanValue(item, 'isActive');
      subscription.name = this._getStringValue(item, 'name');
      subscription.price = this._getNumberValue(item, 'price');
      subscription.subscriptionId = this._getStringValue(item, 'subscriptionId');
      subscription.cancelled = this._getBooleanValue(item, 'cancelled');
      subscription.subscriptionPlanId = this._getStringValue(item, 'subscriptionPlanId');

      subscription.subscriptionPlan = item.get('subscriptionPlan');

      return subscription;
    },
    convertToFollowed: function convertToFollowed(item) {
      var followed = {};

      var objectId = item.id;
      if (objectId == undefined) {
        followed.objectId = '';
      } else {
        followed.objectId = objectId;
      }

      var account = item.get('account');
      if (account == undefined) {
        followed.account = {};
      } else {
        followed.account = this.convertToAccount(account);
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        followed.createdAt = '';
      } else {
        followed.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        followed.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        followed.updatedAt = '';
      } else {
        followed.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        followed.shortDateUpdated = shortDateUpdated;
      }

      var user = item.get('user');
      if (user == undefined) {
        followed.user = {};
      } else {
        followed.user = user;
      }

      followed.lastMessage = item.get('lastMessage');
      followed.accountId = this._getStringValue(item, 'accountId');

      return followed;
    },


    convertToConversationInformationFunc: function convertToConversationInformationFunc(item) {
      var conversationInfo = {};

      conversationInfo.objectId = this._getStringValue(item, 'objectId');
      conversationInfo.subscriptionSpend = this._getNumberValue(item, 'subscriptionSpend');
      conversationInfo.subscriptionSpend = this._getNumberValue(item, 'subscriptionSpend');
      conversationInfo.tipSpend = this._getNumberValue(item, 'tipSpend');
      conversationInfo.lastPurchaseDate = this._getDateValue(item, 'lastPurchaseDate');
      conversationInfo.lastPurchaseDate = this._getDateValue(item, 'lastPurchaseDate');
      conversationInfo.ppvSpend = this._getNumberValue(item, 'ppvSpend');
      conversationInfo.numberOfMessages = this._getNumberValue(item, 'numberOfMessages');
      conversationInfo.updatedAt = this._getDateValue(item, 'updatedAt');
      conversationInfo.state = this._getStringValue(item, 'state');
      conversationInfo.lastSeen = this._getDateValue(item, 'lastSeen');
      conversationInfo.status = this._getStringValue(item, 'status');
      conversationInfo.totalSpend = this._getNumberValue(item, 'totalSpend');
      conversationInfo.createdAt = this._getDateValue(item, 'createdAt');
      conversationInfo.lastMessageDate = this._getDateValue(item, 'lastMessageDate');
      conversationInfo.tagArray = item.get('tagArray');
      conversationInfo.lastMessage = item.get('lastMessage');

      return conversationInfo;
    },

    convertToConversation: function convertToConversation(item) {
      var conversation = {};

      var objectId = item.id;
      if (objectId == undefined) {
        conversation.objectId = '';
      } else {
        conversation.objectId = objectId;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        conversation.createdAt = '';
      } else {
        conversation.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        conversation.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        conversation.updatedAt = '';
      } else {
        conversation.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        conversation.shortDateUpdated = shortDateUpdated;
      }

      conversation.subscription = item.get('subscription');
      conversation.lastMessage = item.get('lastMessage');
      conversation.account = item.get('account');
      conversation.conversationInformation = item.get('conversationInformation');
      conversation.user = item.get('user');
      conversation.accountId = this._getStringValue(item, 'accountId');
      conversation.conversationInformationId = this._getStringValue(item, 'conversationInformationId');
      conversation.lastMessageId = this._getStringValue(item, 'lastMessageId');
      conversation.numberOfMessages = this._getNumberValue(item, 'numberOfMessages');
      conversation.userId = this._getStringValue(item, 'userId');
      conversation.isUnread = this._getBooleanValue(item, 'isUnread');
      conversation.messagesDateFormat = this._messagesDateFormat(conversation.updatedAt);

      return conversation;
    },
    convertToMessagesConversation: function convertToMessagesConversation(item) {
      var messagesConvo = {};

      var objectId = item.id;
      if (objectId == undefined) {
        messagesConvo.objectId = '';
      } else {
        messagesConvo.objectId = objectId;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        messagesConvo.createdAt = '';
      } else {
        messagesConvo.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        messagesConvo.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (updatedAt == undefined) {
        messagesConvo.updatedAt = '';
      } else {
        messagesConvo.updatedAt = updatedAt;
        var shortDateUpdated = updatedAt.toLocaleDateString();
        messagesConvo.shortDateUpdated = shortDateUpdated;
      }

      messagesConvo.account = item.get('account');
      messagesConvo.accountId = this._getStringValue(item, 'accountId');
      messagesConvo.accountMessage = this._getStringValue(item, 'accountMessage');
      messagesConvo.conversation = item.get('conversation');
      messagesConvo.conversationId = this._getStringValue(item, 'conversationId');
      messagesConvo.fromAccount = this._getBooleanValue(item, 'fromAccount');
      messagesConvo.fromUser = this._getBooleanValue(item, 'fromUser');
      messagesConvo.userId = this._getStringValue(item, 'userId');
      messagesConvo.isUnread = this._getBooleanValue(item, 'isUnread');
      messagesConvo.user = item.get('user');
      messagesConvo.userMessage = this._getStringValue(item, 'userMessage');
      messagesConvo.voiceNoteFile = this._getVoiceNoteObjValue(item, 'voiceNoteFile');

      if (messagesConvo.voiceNoteFile === undefined) {
        delete messagesConvo.voiceNoteFile;
      }
      messagesConvo.isTip = this._getBooleanValue(item, 'isTip');
      messagesConvo.tipAmount = this._getNumberValue(item, 'tipAmount');

      return messagesConvo;
    },
    _getStringValue: function _getStringValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value : '';
    },
    _getNumberValue: function _getNumberValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value : 0;
    },
    _getBooleanValue: function _getBooleanValue(item, attribute, defaultValue) {
      var value = item.get(attribute);
      return value !== undefined ? value : defaultValue !== undefined ? defaultValue : false;
    },
    _getDateValue: function _getDateValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value : '';
    },
    _getShortDateValue: function _getShortDateValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value.toLocaleDateString() : '';
    },
    _getArrayValue: function _getArrayValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value : [];
    },
    _getObjValue: function _getObjValue(item, attribute) {
      var value = item.get(attribute);
      return value !== undefined ? value : {};
    },
    _getVoiceNoteObjValue: function _getVoiceNoteObjValue(item, attribute) {
      var value = item.get(attribute);
      if (attribute === 'voiceNoteFile' && value) {
        var _name = value._name,
            _url = value._url;

        return { name: _name, url: _url };
      }
      return value !== undefined ? value : undefined;
    },
    _getTimeOfDate: function _getTimeOfDate(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      hours = hours <= 9 ? '0' + hours : '' + hours;
      minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
      return hours + ':' + minutes + ampm;
    },
    _getMonthDayOfDate: function _getMonthDayOfDate(date) {
      var day = date.getDate();
      var month = date.getMonth() + 1;
      day = day <= 9 ? '0' + day : '' + day;
      month = month <= 9 ? '0' + month : '' + month;
      return month + '/' + day;
    },
    _getTimeAgo: function _getTimeAgo(postDate) {
      var postTime = new Date(postDate).getTime();
      var currentTime = new Date().getTime();
      var timeDifference = currentTime - postTime;

      var seconds = Math.floor(timeDifference / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      if (days > 0) {
        return days + (days === 1 ? ' day ago' : ' days ago');
      } else if (hours > 0) {
        return hours + (hours === 1 ? ' hour ago' : ' hours ago');
      } else if (minutes > 0) {
        return minutes + (minutes === 1 ? ' minute ago' : ' minutes ago');
      } else {
        return seconds + (seconds === 1 ? ' second ago' : ' seconds ago');
      }
    },
    _messagesDateFormat: function _messagesDateFormat(date) {
      var messageDate = new Date(date);
      var currentDate = new Date();

      // Check if message was sent within the day
      if (currentDate.getDate() === messageDate.getDate() && currentDate.getMonth() === messageDate.getMonth() && currentDate.getFullYear() === messageDate.getFullYear()) {
        var hours = String(messageDate.getHours()).padStart(2, '0');
        var minutes = String(messageDate.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
      }

      // Check if message was sent within the week
      var oneDay = 24 * 60 * 60 * 1000;
      if (currentDate - messageDate < 7 * oneDay) {
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[messageDate.getDay()];
      }

      // Check if message was sent within the month
      if (currentDate.getMonth() === messageDate.getMonth() && currentDate.getFullYear() === messageDate.getFullYear()) {
        var _day = String(messageDate.getDate()).padStart(2, '0');
        var _month = String(messageDate.getMonth() + 1).padStart(2, '0');
        return _month + '/' + _day;
      }

      // If message was sent in a different year
      var day = String(messageDate.getDate()).padStart(2, '0');
      var month = String(messageDate.getMonth() + 1).padStart(2, '0');
      var year = String(messageDate.getFullYear()).substring(2);
      return month + '/' + day + '/' + year;
    }
  });
});