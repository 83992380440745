define('xchatter-webapp/helpers/-clear-element', ['exports', 'ember-in-element-polyfill/helpers/-clear-element'], function (exports, _clearElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clearElement.default;
    }
  });
});