define('xchatter-webapp/components/action-sheets/upgrade-membership-action-sheet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notificationService: Ember.inject.service('notification'),
    spinnerService: Ember.inject.service('spinner'),

    actions: {
      upgradeMembership: function upgradeMembership() {
        try {
          this.get('notificationService').success('<div class="c-content-text">\n            <h3 class="c-notification__title">Success!</h3>\n            <p class="c-notification__sub-title">Membership upgraded.</p>\n          </div>\n          ', { htmlContent: true });
          this.get('cancel')();
        } catch (error) {
          this.get('notificationService').error('<div class="c-content-text">\n            <h3 class="c-notification__title">Something went wrong</h3>\n            <p class="c-notification__sub-title">' + error.message + '</p>\n          </div>\n          ', { htmlContent: true });
        }
      }
    }
  });
});