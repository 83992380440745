define('xchatter-webapp/components/main-tabs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routerService: Ember.inject.service('router'),

    isBookmarksScreen: Ember.computed.equal('routerService.currentRouteName', 'bookmarks'),
    isAccountScreen: Ember.computed.equal('routerService.currentRouteName', 'account'),
    isSubscriptionsScreen: Ember.computed.equal('routerService.currentRouteName', 'subscriptions'),
    isFollowingScreen: Ember.computed.equal('routerService.currentRouteName', 'following'),
    isPrivacyScreen: Ember.computed.equal('routerService.currentRouteName', 'privacy'),
    isSupportScreen: Ember.computed.equal('routerService.currentRouteName', 'support'),
    isAboutScreen: Ember.computed.equal('routerService.currentRouteName', 'about'),

    contactIconCurrentWhen: Ember.computed('isAccountScreen', 'isSubscriptionsScreen', 'isFollowingScreen', 'isPrivacyScreen', 'isSupportScreen', 'isAboutScreen', function () {
      if (this.get('isAccountScreen')) {
        return 'account';
      } else if (this.get('isSubscriptionsScreen')) {
        return 'subscriptions';
      } else if (this.get('isFollowingScreen')) {
        return 'following';
      } else if (this.get('isPrivacyScreen')) {
        return 'privacy';
      } else if (this.get('isSupportScreen')) {
        return 'support';
      } else if (this.get('isAboutScreen')) {
        return 'about';
      } else {
        return null;
      }
    })
  });
});