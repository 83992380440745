define('xchatter-webapp/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    parseService: Ember.inject.service('parse'),

    beforeModel: function beforeModel() {
      var currentUser = this.get('parseService.user').current();

      if (currentUser) {
        this.transitionTo('feed');
      }
    }
  });
});