define('xchatter-webapp/components/firstload-trigger', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    didEnterViewport: function didEnterViewport() {
      this.loadAccount();
    },
    didExitViewport: function didExitViewport() {},
    didScroll: function didScroll() {
      //console.log(direction); // 'up' || 'down' || 'left' || 'right'
    },
    didInsertElement: function didInsertElement() {
      this.setProperties({
        viewportEnabled: true,
        viewportUseRAF: true,
        viewportSpy: true,
        viewportScrollSensitivity: 1,
        viewportRefreshRate: 150,
        viewportTolerance: {
          top: 50,
          bottom: 50,
          left: 20,
          right: 20
        }
      });
    }
  });
});