define("xchatter-webapp/services/window-navigator", ["exports", "bowser"], function (exports, _bowser) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentOS: function currentOS() {
      if (window && window.navigator && window.navigator.userAgent) {
        var browser = _bowser.default.getParser(window.navigator.userAgent);

        if (browser && browser.parsedResult && browser.parsedResult.os) {
          return browser.parsedResult.os;
        }
      }
    },
    currentBrowser: function currentBrowser() {
      if (window && window.navigator && window.navigator.userAgent) {
        var browser = _bowser.default.getParser(window.navigator.userAgent);

        if (browser && browser.parsedResult && browser.parsedResult.browser) {
          return browser.parsedResult.browser;
        }
      }
    }
  });
});