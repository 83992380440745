define('xchatter-webapp/services/posts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service('parse'),
    parseConverterService: Ember.inject.service('parse-converter'),
    activePost: null,
    allPosts: null,
    allPostsBookmarks: null,
    allPostsProfile: null,

    setPosts: function setPosts(posts) {
      this.set('allPosts', posts);
    },
    setPostsProfile: function setPostsProfile(posts) {
      this.set('allPostsProfile', posts);
    },
    setPost: function setPost(post) {
      this.set('activePost', post);
    },
    setPostsBookmarks: function setPostsBookmarks(bookmarks) {
      this.set('allPostsBookmarks', bookmarks);
    },


    // getPosts(params) {
    //   return this.get('parseService.cloud').run('getPosts', params);
    // },

    getPosts: function getPosts(params) {
      var _this = this;

      return this.get('parseService.cloud').run('getPosts', params).then(function (response) {
        var postRes = response.map(function (post) {
          return _this.get('parseConverterService').convertToPost(post);
        });

        return postRes;
      });
    },


    // this is for getting post on profile (can view post even user was not sign in)
    getPostsForProfile: function getPostsForProfile(params) {
      var _this2 = this;

      return this.get('parseService.cloud').run('getPostsForProfile', params).then(function (response) {
        var postProfileRes = response.map(function (post) {
          return _this2.get('parseConverterService').convertToPost(post);
        });

        return postProfileRes;
      });
    },


    // getBookmarks(params) {
    //   return this.get('parseService.cloud').run('getBookmarks', params);
    // },

    getBookmarks: function getBookmarks(params) {
      var _this3 = this;

      return this.get('parseService.cloud').run('getBookmarks', params).then(function (response) {
        var bookmarkRes = response.map(function (book) {
          return _this3.get('parseConverterService').convertToBookmark(book);
        });

        return bookmarkRes;
      });
    },
    getBookmarksWithImages: function getBookmarksWithImages(params) {
      var _this4 = this;

      return this.get('parseService.cloud').run('getBookmarksWithImages', params).then(function (response) {
        var bookmarkRes = response.map(function (book) {
          return _this4.get('parseConverterService').convertToBookmark(book);
        });

        return bookmarkRes;
      });
    },
    createOrDestroyBookmark: function createOrDestroyBookmark(params) {
      return this.get('parseService.cloud').run('createOrDestroyBookmark', params);
    },
    createOrDestroyPostLike: function createOrDestroyPostLike(params) {
      return this.get('parseService.cloud').run('createOrDestroyPostLike', params);
    },


    // getYourPostsLike(params) {
    //   return this.get('parseService.cloud').run('getYourPostsLike', params);
    // },

    getYourPostsLike: function getYourPostsLike(params) {
      var _this5 = this;

      return this.get('parseService.cloud').run('getYourPostsLike', params).then(function (response) {
        if (typeof response === 'string' || response instanceof String) {
          return [];
        } else {
          var postLikeRes = response.map(function (post) {
            return _this5.get('parseConverterService').convertToPostLike(post);
          });

          return postLikeRes;
        }
      });
    }
  });
});