define('xchatter-webapp/services/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service('parse'),

    disableOrEnableTrainingData: function disableOrEnableTrainingData(params) {
      // params = const disableTrainingData = req.params.disableTrainingData;
      return this.get('parseService.cloud').run('disableOrEnableTrainingData', params);
    }
  });
});