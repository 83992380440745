define('xchatter-webapp/controllers/code-verification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    mediaService: Ember.inject.service('media'),

    actions: {
      submit: function submit() {
        this.transitionToRoute('sign-up');
      }
    }
  });
});