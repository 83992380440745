define('xchatter-webapp/controllers/subscription/month', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      submit: function submit() {
        try {
          this.transitionToRoute('subscription.success');
        } catch (error) {
          return error;
        }
      }
    }
  });
});