define('xchatter-webapp/components/myview-trigger', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    timer: null,

    didEnterViewport: function didEnterViewport() {
      var _this = this;

      this.timer = Ember.run.later(function () {
        _this.sendAction('loadMoreItems');
      }, 300);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('onWillDestroyElement')) this.get('onWillDestroyElement')();
      this._super.apply(this, arguments);

      Ember.run.cancel(this.timer);
    },
    didExitViewport: function didExitViewport() {
      //console.log('exited component');
    },
    didScroll: function didScroll() {
      //console.log(direction); // 'up' || 'down' || 'left' || 'right'
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.isDestroyed && !this.isDestroying) {
        Ember.setProperties(this, {
          viewportEnabled: true,
          viewportUseRAF: true,
          viewportSpy: true,
          viewportScrollSensitivity: 1,
          viewportRefreshRate: 150,
          viewportTolerance: {
            top: 50,
            bottom: 50,
            left: 20,
            right: 20
          }
        });
      }
    }
  });
});