define('xchatter-webapp/routes/about-you/interests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ id: 1, name: 'Feet Pics 🦶' }, { id: 2, name: 'Dirty Talking 😉' }, { id: 3, name: 'BDSM ⛓️' }, { id: 4, name: 'Exhibitionism 🎭' }, { id: 5, name: 'Hosiery 🧦' }, { id: 6, name: 'Corsetry 🩱' }, { id: 7, name: 'Omorashi 💦' }, { id: 8, name: 'Orgy 🎉' }, { id: 9, name: 'Face Sitting 🧎‍♂️' }, { id: 10, name: 'Strap On 🍆' }, { id: 11, name: 'Cunnilingus 👅' }, { id: 12, name: 'Fisting 👊' }, { id: 13, name: 'Joi 🫴' }, { id: 14, name: 'Degradation 🗣' }, { id: 15, name: 'Financial Domination 💰' }, { id: 16, name: 'Edging 🏁' }, { id: 17, name: 'Cupping 🤲' }, { id: 18, name: 'Roleplay 🎬' }, { id: 19, name: 'Threesomes 👨‍👧‍👧' }, { id: 20, name: 'Swinging 🏌️' }, { id: 21, name: 'Cuckolding 👀' }, { id: 22, name: 'Chasity 🔒' }, { id: 23, name: 'Furry 🦊' }, { id: 24, name: 'Chasity 🔒' }, { id: 25, name: 'Voyeurism 🔭' }];
    }
  });
});