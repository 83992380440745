define('xchatter-webapp/components/infinite-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this = this;

      var wrapper = this.element.querySelector('.scroll-wrapper');

      var actionTriggered = false;
      wrapper.addEventListener('scroll', function () {
        var halfwayPoint = wrapper.scrollHeight / 2;

        if (wrapper.scrollTop + wrapper.clientHeight >= halfwayPoint) {
          if (!actionTriggered) {
            _this.sendAction('action');
            actionTriggered = true;
          }
        } else {
          actionTriggered = false;
        }
      });
    }
  });
});