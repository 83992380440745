define('xchatter-webapp/components/shop-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      selectPurchase: function selectPurchase(shopItem) {
        this.get('setActivePurchase')(shopItem);
        this.get('togglePurchase')();
      }
    }
  });
});