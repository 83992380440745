define('xchatter-webapp/services/conversation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service('parse'),
    parseConverterService: Ember.inject.service('parse-converter'),

    createOrFindWebBasedTwilioConversation: function createOrFindWebBasedTwilioConversation(params) {
      var _this = this;

      return this.get('parseService.cloud').run('createOrFindWebBasedTwilioConversation', params).then(function (response) {
        return _this.get('parseConverterService').convertToTwilioConversation(response);
      });
    },
    sendVoiceMessageForWebBasedTwilioConversation: function sendVoiceMessageForWebBasedTwilioConversation(params) {
      return this.get('parseService.cloud').run('sendVoiceMessageForWebBasedTwilioConversation', params);
    },
    getSingleChatOfConversationForWebBasedConversation: function getSingleChatOfConversationForWebBasedConversation(params) {
      return this.get('parseService.cloud').run('getSingleChatOfConversationForWebBasedConversation', params);
    },
    sendMessageForWebBasedTwilioConversation: function sendMessageForWebBasedTwilioConversation(params) {
      return this.get('parseService.cloud').run('sendMessageForWebBasedTwilioConversation', params);
    }
  });
});