define('xchatter-webapp/validations/subscription', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function validateCardExpiry(key, value) {
    var _value$split = value.split('/'),
        _value$split2 = _slicedToArray(_value$split, 2),
        month = _value$split2[0],
        year = _value$split2[1];

    var expiryDate = new Date('20' + year, month - 1);
    var today = new Date();

    // Reset the time part of the dates to compare only the date part
    expiryDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return expiryDate >= today ? true : 'The card has expired.';
  }

  exports.default = {
    cardNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 13, max: 16 })],
    cardExpiry: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ is: 5 }), validateCardExpiry],
    cardCVC: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3, max: 4 })],
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    streetAddress: (0, _validators.validatePresence)(true),
    city: (0, _validators.validatePresence)(true),
    state: (0, _validators.validatePresence)(true),
    zipCode: [(0, _validators.validatePresence)(true)],
    country: (0, _validators.validatePresence)(true)
  };
});