define('xchatter-webapp/services/parse', ['exports', 'parse'], function (exports, _parse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _parse.default.initialize('DurIFZjniGfDr7znAqfrqNUNuEt0xyRCsHE1ovvC', 'ZY4og7q9jfDaj0XdvE4Wa14u4PTE2SMpnFU5qVN8');
  _parse.default.serverURL = 'https://zoomy.b4a.io/';

  exports.default = Ember.Service.extend({
    cloud: Ember.computed.alias('parse.Cloud'),
    user: Ember.computed.alias('parse.User'),
    query: Ember.computed.alias('parse.Query'),
    liveQuery: Ember.computed.alias('parse.LiveQuery'),
    file: Ember.computed.alias('parse.File'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('parse', _parse.default);
    }
  });
});