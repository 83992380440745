define('xchatter-webapp/routes/chats/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var data = [{ id: 1, imageURL: '/svg/matches/matches-2.svg' }, { id: 2, imageURL: '/svg/matches/matches-2.svg' }, { id: 3, imageURL: '/svg/matches/matches-2.svg' }, { id: 4, imageURL: '/svg/matches/matches-2.svg' }, { id: 5, imageURL: '/svg/matches/matches-2.svg' }, { id: 6, imageURL: '/svg/matches/matches-2.svg' }, { id: 7, imageURL: '/svg/matches/matches-2.svg' }, { id: 8, imageURL: '/svg/matches/matches-2.svg' }];

  exports.default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return new Promise(function (resolve) {
        Ember.run.later(_this, function () {
          resolve(data);
        }, 500);
      });
    }
  });
});