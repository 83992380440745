define('xchatter-webapp/services/camera', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isSupported: Ember.computed('navigator', function () {
      return 'mediaDevices' in this.get('navigator') && 'getUserMedia' in this.get('navigator.mediaDevices');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('navigator', window.navigator);
    },
    getCameraStream: function getCameraStream() {
      if (this.get('isSupported')) {
        return this.get('navigator.mediaDevices').getUserMedia({ video: true });
      } else {
        return Promise.reject(new Error('Web camera is not supported'));
      }
    }
  });
});